//本地导入
import request from "../request";

//分类列表
export const listMedalCategory = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/category/list",
  });

//分类保存
export const saveMedalCategory = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/category/save",
  });

//分类搜索
export const searchMedalCategory = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/category/search",
  });

//勋章保存
export const saveMedal = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/save",
  });

//勋章列表
export const listMedal = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/list",
  });

//勋章搜索
export const searchMedal = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/search",
  });

//勋章删除
export const deleteMedal = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/delete",
  });

//勋章用户保存
export const saveMedalUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/user/save",
  });

//勋章用户列表
export const listMedalUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/user/list",
  });

//勋章用户删除
export const deleteMedalUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/medal/user/delete",
  });
