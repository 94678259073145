
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { listMedalCategory, saveMedalCategory, searchMedalCategory } from "@/api/request/medal";

//组件
@Component({
  name: "SystematicsList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    page_size: 20,

    //请求数据
    name: "",
  };

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await listMedalCategory(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //基本信息
      id: 0, //分类ID
      sort: 0, //排列顺序

      //名称列表
      name_list: {
        zh: "",
        en: "",
        id: "",
      },
    };
    this.dialogStatus = "create";

    //显示对话框
    this.dialogVisible = true;
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      //基本信息
      id: row.id, //分类ID
      sort: row.sort, //排列顺序

      //名称列表
      name_list: row.name_list,
    };
    this.dialogStatus = "update";

    //显示对话框
    this.dialogVisible = true;
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private titleMap = { update: "编辑分类", create: "新增分类" };

  //添加表单
  private addForm: any = {
    //基本信息
    id: 0, //分类ID
    sort: 0, //排列顺序

    //名称列表
    name_list: {
      zh: "",
      en: "",
      id: "",
    },
  };

  //创建分类
  private async createData() {
    //判断返回
    if (this.addForm.name_list.zh == "") return Message({ message: "请输入分类中文名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.en == "") return Message({ message: "请输入分类英文名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.id == "") return Message({ message: "请输入分类印尼语名称！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort);

    //添加
    await saveMedalCategory(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }

  //更新分类
  private async updateData() {
    //判断返回
    if (this.addForm.name_list.zh == "") return Message({ message: "请输入分类中文名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.en == "") return Message({ message: "请输入分类英文名称！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.id == "") return Message({ message: "请输入分类印尼语名称！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort);

    //添加
    await saveMedalCategory(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }
}
